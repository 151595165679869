// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-shipment-add-items-js": () => import("/opt/render/project/src/src/pages/BookShipment/AddItems.js" /* webpackChunkName: "component---src-pages-book-shipment-add-items-js" */),
  "component---src-pages-book-shipment-confirmation-js": () => import("/opt/render/project/src/src/pages/BookShipment/Confirmation.js" /* webpackChunkName: "component---src-pages-book-shipment-confirmation-js" */),
  "component---src-pages-book-shipment-review-and-dispatch-js": () => import("/opt/render/project/src/src/pages/BookShipment/ReviewAndDispatch.js" /* webpackChunkName: "component---src-pages-book-shipment-review-and-dispatch-js" */),
  "component---src-pages-book-shipment-route-and-schedule-js": () => import("/opt/render/project/src/src/pages/BookShipment/RouteAndSchedule.js" /* webpackChunkName: "component---src-pages-book-shipment-route-and-schedule-js" */),
  "component---src-pages-book-shipment-route-and-schedule-destination-js": () => import("/opt/render/project/src/src/pages/BookShipment/RouteAndScheduleDestination.js" /* webpackChunkName: "component---src-pages-book-shipment-route-and-schedule-destination-js" */),
  "component---src-pages-book-shipment-select-carrier-js": () => import("/opt/render/project/src/src/pages/BookShipment/SelectCarrier.js" /* webpackChunkName: "component---src-pages-book-shipment-select-carrier-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoices-open-invoice-js": () => import("/opt/render/project/src/src/pages/Invoices/OpenInvoice.js" /* webpackChunkName: "component---src-pages-invoices-open-invoice-js" */),
  "component---src-pages-invoices-open-invoice-credit-card-js": () => import("/opt/render/project/src/src/pages/Invoices/OpenInvoiceCreditCard.js" /* webpackChunkName: "component---src-pages-invoices-open-invoice-credit-card-js" */),
  "component---src-pages-invoices-open-invoice-two-js": () => import("/opt/render/project/src/src/pages/Invoices/OpenInvoiceTwo.js" /* webpackChunkName: "component---src-pages-invoices-open-invoice-two-js" */),
  "component---src-pages-invoices-paid-invoice-js": () => import("/opt/render/project/src/src/pages/Invoices/PaidInvoice.js" /* webpackChunkName: "component---src-pages-invoices-paid-invoice-js" */),
  "component---src-pages-page-2-js": () => import("/opt/render/project/src/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-quote-add-items-js": () => import("/opt/render/project/src/src/pages/quote/AddItems.js" /* webpackChunkName: "component---src-pages-quote-add-items-js" */),
  "component---src-pages-quote-carrier-results-js": () => import("/opt/render/project/src/src/pages/quote/CarrierResults.js" /* webpackChunkName: "component---src-pages-quote-carrier-results-js" */),
  "component---src-pages-quote-carrier-results-two-js": () => import("/opt/render/project/src/src/pages/quote/CarrierResultsTwo.js" /* webpackChunkName: "component---src-pages-quote-carrier-results-two-js" */),
  "component---src-pages-quote-confirmation-js": () => import("/opt/render/project/src/src/pages/quote/Confirmation.js" /* webpackChunkName: "component---src-pages-quote-confirmation-js" */),
  "component---src-pages-quote-get-quote-js": () => import("/opt/render/project/src/src/pages/quote/GetQuote.js" /* webpackChunkName: "component---src-pages-quote-get-quote-js" */),
  "component---src-pages-quote-review-and-dispatch-js": () => import("/opt/render/project/src/src/pages/quote/ReviewAndDispatch.js" /* webpackChunkName: "component---src-pages-quote-review-and-dispatch-js" */),
  "component---src-pages-quote-route-and-schedule-js": () => import("/opt/render/project/src/src/pages/quote/RouteAndSchedule.js" /* webpackChunkName: "component---src-pages-quote-route-and-schedule-js" */),
  "component---src-pages-quote-select-carrier-js": () => import("/opt/render/project/src/src/pages/quote/SelectCarrier.js" /* webpackChunkName: "component---src-pages-quote-select-carrier-js" */),
  "component---src-pages-quote-truckload-js": () => import("/opt/render/project/src/src/pages/quote/Truckload.js" /* webpackChunkName: "component---src-pages-quote-truckload-js" */),
  "component---src-pages-quotes-active-quote-js": () => import("/opt/render/project/src/src/pages/Quotes/ActiveQuote.js" /* webpackChunkName: "component---src-pages-quotes-active-quote-js" */),
  "component---src-pages-quotes-booked-quote-js": () => import("/opt/render/project/src/src/pages/Quotes/BookedQuote.js" /* webpackChunkName: "component---src-pages-quotes-booked-quote-js" */),
  "component---src-pages-quotes-expired-quote-js": () => import("/opt/render/project/src/src/pages/Quotes/ExpiredQuote.js" /* webpackChunkName: "component---src-pages-quotes-expired-quote-js" */),
  "component---src-pages-settings-location-js": () => import("/opt/render/project/src/src/pages/Settings/Location.js" /* webpackChunkName: "component---src-pages-settings-location-js" */),
  "component---src-pages-shipments-shipment-js": () => import("/opt/render/project/src/src/pages/Shipments/Shipment.js" /* webpackChunkName: "component---src-pages-shipments-shipment-js" */),
  "component---src-pages-shipments-shipment-delivered-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentDelivered.js" /* webpackChunkName: "component---src-pages-shipments-shipment-delivered-js" */),
  "component---src-pages-shipments-shipment-details-shipment-booked-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentDetails/ShipmentBooked.js" /* webpackChunkName: "component---src-pages-shipments-shipment-details-shipment-booked-js" */),
  "component---src-pages-shipments-shipment-details-shipment-checked-in-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentDetails/ShipmentCheckedIn.js" /* webpackChunkName: "component---src-pages-shipments-shipment-details-shipment-checked-in-js" */),
  "component---src-pages-shipments-shipment-details-shipment-delivered-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentDetails/ShipmentDelivered.js" /* webpackChunkName: "component---src-pages-shipments-shipment-details-shipment-delivered-js" */),
  "component---src-pages-shipments-shipment-details-shipment-ordered-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentDetails/ShipmentOrdered.js" /* webpackChunkName: "component---src-pages-shipments-shipment-details-shipment-ordered-js" */),
  "component---src-pages-shipments-shipment-details-shipment-picked-up-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentDetails/ShipmentPickedUp.js" /* webpackChunkName: "component---src-pages-shipments-shipment-details-shipment-picked-up-js" */),
  "component---src-pages-shipments-shipment-details-shipment-route-map-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentDetails/ShipmentRouteMap.js" /* webpackChunkName: "component---src-pages-shipments-shipment-details-shipment-route-map-js" */),
  "component---src-pages-shipments-shipment-in-transit-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentInTransit.js" /* webpackChunkName: "component---src-pages-shipments-shipment-in-transit-js" */),
  "component---src-pages-shipments-shipment-scheduled-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentScheduled.js" /* webpackChunkName: "component---src-pages-shipments-shipment-scheduled-js" */),
  "component---src-pages-shipments-shipment-two-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentTwo.js" /* webpackChunkName: "component---src-pages-shipments-shipment-two-js" */),
  "component---src-pages-shipments-shipment-watchlist-js": () => import("/opt/render/project/src/src/pages/Shipments/ShipmentWatchlist.js" /* webpackChunkName: "component---src-pages-shipments-shipment-watchlist-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/render/project/src/.cache/data.json")

